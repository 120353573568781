import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  createRef,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import lottie from "lottie-web";

import { Card } from "react-bootstrap";
import HomeHero from "../components/home-hero";
import StarLogo from "../images/icons/star.svg";
import FacebookLogo from "../images/mdi_facebook.svg";
import XLogo from "../images/Vector (13).svg";
import InstaLogo from "../images/ri_instagram-fill.svg";
import ContactForm from "../components/contactForm";
import HeroOther from "../components/hero-other";

const ContactUsPage = () => {
  const tl = useRef();
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "contact-1" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      Img1: wpMediaItem(title: { eq: "Pantry" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      Img2: wpMediaItem(title: { eq: "Tanishq" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      Img3: wpMediaItem(title: { eq: "index-8" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }

      # allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
      #   nodes {
      #     localFile {
      #       childImageSharp {
      #         gatsbyImageData(
      #           formats: [AUTO, WEBP]
      #           quality: 100
      #           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
      #           layout: CONSTRAINED
      #           placeholder: BLURRED
      #         )
      #       }
      #     }
      #     caption

      #     permalink
      #     timestamp
      #   }
      # }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Contact Us",
        item: {
          url: `${siteUrl}/contact-us`,
          id: `${siteUrl}/contact-us`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title="Contact Us | Toot Hill Golf Course"
        description="Get in touch with us if you have any questions about Toot Hill Golf Course or our facilities."
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/contact-us`,
          title: "Contact Us | Toot Hill Golf Course",
          description:
            "Get in touch with us if you have any questions about Toot Hill Golf Course or our facilities.",
          images: [
            {
              url: `${data.heroImg?.localFile.publicURL}`,
              width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
              height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
              alt: `${data.heroImg?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          <HeroOther
            smallHeading="Talk to us"
            title="Contact us"
            description={
              <>
                Get in touch with us if you have any questions about Toot
                <br className="d-none d-md-block" /> Hill Golf Course or our
                facilities.
              </>
            }
            externalLink
            button1Text="Reserve a Tee Time"
            button1Link="https://bookings.toothillgolfclub.co.uk/"
            button2Text="Society Enquiries"
            button2Link="/society-enquiries"
            backgroundImage={
              data.heroImg.localFile.childImageSharp.gatsbyImageData
            }
          />

          <section className="pt-5 pt-lg-7">
            <Container>
              <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                <Col lg={8}>
                  <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                    Enquiries
                  </p>
                  <h2 className="line-heading-dark text-center  text-dark-green mb-5 fs-1">
                    Get in touch
                  </h2>
                </Col>
              </Row>

              <Row className="g-5 gx-lg-7 align-items-center">
                <Col lg={6}>
                  <iframe
                    className="position-relative google-map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2473.0311425588225!2d0.1911667763615362!3d51.695875196917385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d897e47c2e754f%3A0xb7f81d8f596b4b47!2sToot%20Hill%20Golf%20Club!5e0!3m2!1sen!2suk!4v1695664623164!5m2!1sen!2suk"
                    width="100%"
                    style="border:0;"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Col>
                <Col lg={6}>
                  {" "}
                  <p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
                    Address
                  </p>
                  <a
                    className="black-link fs-6 "
                    target="_blank"
                    rel="noreferrer"
                    href="https://maps.app.goo.gl/rQXW6dszNhtHBDRS9"
                  >
                    Toot Hill Golf Club, School Road, Toot Hill, Ongar, Essex,
                    CM5 9PU
                  </a>
                  <p className="text-uppercase pt-4 text-secondary pb-2 mb-0 ssp-semibold">
                    Phone
                  </p>
                  <a className="black-link fs-6 " href="tel:+441277365523">
                    01277 365 523
                  </a>
                  <p className="text-uppercase  pt-4 text-secondary pb-2 mb-0 ssp-semibold">
                    Email
                  </p>
                  <a
                    className="black-link fs-6 "
                    href="mailto:office@toothillgolfclub.co.uk"
                  >
                    office@toothillgolfclub.co.uk
                  </a>
                  <p className="text-uppercase  text-secondary pt-4 pb-2 mb-0 ssp-semibold">
                    Office Hours
                  </p>
                  <p className=" pb-0 mb-0 fs-6 ssp-semibold">
                    Monday, Wednesday, Friday between 9:00am and 2:00pm
                  </p>
                  <div className=" mt-3 d-flex  align-items-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/TootHillGolfClub/?locale=en_GB"
                    >
                      <img
                        src={FacebookLogo}
                        alt="Facebook Logo"
                        className=" me-4  d-inline-block "
                        style={{
                          maxWidth: "100px",
                        }}
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/toothill_golf_club/"
                    >
                      <img
                        src={InstaLogo}
                        alt="Instagram Logo"
                        className=" me-4 d-inline-block "
                        style={{
                          maxWidth: "100px",
                        }}
                      />
                    </a>

                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/toothill_golf?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                    >
                      <img
                        src={XLogo}
                        alt="X Logo"
                        className=" me-4 d-inline-block "
                        style={{
                          maxWidth: "100px",
                        }}
                      />
                    </a>
                  </div>
                  <p className="text-uppercase  text-secondary pt-4 pb-2 mb-0 ssp-semibold">
                    The Pantry
                  </p>
                  <p className=" pb-0 mb-0 fs-6 ssp-semibold">
                    Everyday from 7:30am for lunch and dinner
                  </p>
                  <div className=" mt-3 d-flex  align-items-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/thepantry18_/"
                    >
                      <img
                        src={InstaLogo}
                        alt="Instagram Logo"
                        className=" me-4 d-inline-block "
                        style={{
                          maxWidth: "100px",
                        }}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-5 py-lg-7">
            <Container>
              <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                <Col lg={8}>
                  <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                    Get in touch
                  </p>
                  <h2 className="line-heading-dark text-center  text-dark-green mb-5 fs-1">
                    Send a message
                  </h2>
                </Col>
              </Row>

              <Row className="g-5 justify-content-between align-items-center h-100 gx-lg-7  gx-xl-0">
                <Col lg={6} xl={5}>
                  <ContactForm />
                </Col>
                <Col styl={{ minHeight: "100%" }} lg={6} xl={5}>
                  <div
                    className="h-100"
                    style={{ borderRadius: "8px", overflow: "hidden" }}
                  >
                    <GatsbyImage
                      style={{ borderRadius: "8px" }}
                      className="w-100 h-100"
                      image={
                        data.Img3.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={data.Img3.altText}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Layout>
      </div>
    </>
  );
};

export default ContactUsPage;
